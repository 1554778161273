import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, filter, map, Observable, throwError } from 'rxjs';
import { Environment } from '../../environments/environment';
import { IRestResponse } from '../core/interface/response.interface';
import { IAllSubTenants } from '../interfaces/company.interface';
import { RestService } from './rest.service';
import { IDropdown } from '../core/interface/master.interface';
import { IIsWabaVerifiedResp } from '../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class selectedTenantService {

  private selectedTenant$ = new BehaviorSubject<IDropdown | null>(null)
  public selectedTenantObs = this.selectedTenant$.asObservable().pipe(filter(res => res != null));
  set selectedTenantInfo(value: IDropdown) {
    this.selectedTenant$.next(value)
  }
  get selectedTenantInfo(): IDropdown | null {
    return this.selectedTenant$.getValue()
  }

  private selectedTenantWaba$ = new BehaviorSubject<IIsWabaVerifiedResp | null>(null);
  public selectedTenantWabaObs = this.selectedTenantWaba$.asObservable()
  set selectedTenWabaInfo(data: IIsWabaVerifiedResp) {
    if (data) {
      this.selectedTenantWaba$.next({ ...data })
    }
  }
  get selectedTenWabaInfo(): IIsWabaVerifiedResp | null {
    return this.selectedTenantWaba$.getValue()
  }

  resetSelectedTenant() {
    this.selectedTenantWaba$.next(null)
    this.selectedTenant$.next(null)
  }

  constructor(private restService: RestService) { }

  public getSubCompanies(tenantId: string): Observable<IRestResponse<IAllSubTenants>> {
    return this.restService
      .get(Environment.UrlPath + `/company/tenants/${tenantId}`, null, false)
      .pipe(
        map((res: any) => res),
        catchError((error) => throwError(error))
      )
  }

}
